import React from 'react'
import BoxStaticPageList from '../../../src/common/components/BoxStaticPageList/BoxStaticPageList'
import LayoutLanding from '../../../src/common/components/layouts/layoutLanding/LayoutLanding'
import { locale, t } from '../../../src/common/i18n'
import { getShortCompany } from '../../../src/common/utils/getCompany'

interface Props {
  styles: { [k: string]: string }
}

const sectionsEs = [
  {
    title: 'Servicios de Mecánica',
    items: [
      {
        title: 'Frenos de Coche',
        description: `Entender cómo funcionan los frenos te ayudará a saber la mejor forma de sacarles el máximo partido. 
        Te lo explicamos aquí.`,
        img: `https://${process.env.RODI_AWS_BUCKET}/landings/coche/disco-de-freno-pinza-de-freno.jpg`,
        route: 'coche.frenos',
      },
      {
        title: 'Amortiguadores de Coche',
        description: `¿Sabías que los amortiguadores son un elemento clave para que te sientas seguro conduciendo? 
        Te lo explicamos aquí.`,
        img: `https://${process.env.RODI_AWS_BUCKET}/landings/coche/amortiguadores-coche.jpg`,
        route: 'coche.amortiguadores',
      },
      {
        title: 'Correa de Distribución',
        description: `Es la encargada de transmitir la fuerza desde el motor a las ruedas de tu coche. 
        Sin la correa de distribución el movimiento sería imposible. Te explicamos aquí todos los detalles.`,
        img: `https://${process.env.RODI_AWS_BUCKET}/landings/coche/correa-de-distribucion-coche.jpg`,
        route: 'coche.correa',
      },
      {
        title: 'Mecánica y Diagnosis',
        description: `No importa el problema, lo importante es encontrarle solución. 
        En Rodi estamos preparados para tratar cualquier tipo de avería en tu coche. Te explicamos como.`,
        img: `https://${process.env.RODI_AWS_BUCKET}/landings/coche/mecanica-diagnosis-averias-coche.jpg`,
        route: 'coche.mecanica',
      },
    ],
  },
  {
    title: 'Servicios de Mantenimiento',
    items: [
      {
        title: 'Filtros de Coche',
        description: `Un elemento esencial para tu coche que en ocasiones pasa desapercibido. 
        Conoce los diferentes tipos de filtro que existen y para qué sirve cada uno aquí.`,
        img: `https://${process.env.RODI_AWS_BUCKET}/landings/coche/filtro-aceite-coche-primer-plano.jpg`,
        route: 'coche.filtros',
      },
      {
        title: 'Batería de Coche',
        description: `Es la responsable de aportar la energía necesaria para el arranque del motor. 
        Te explicamos qué es, cómo funciona y los diferentes tipos de batería que existen.`,
        img: `https://${process.env.RODI_AWS_BUCKET}/landings/coche/bateria-rodi-coche.jpg`,
        route: 'coche.baterias',
      },
      {
        title: 'Neumáticos de Coche',
        description: `Son los responsables de mantener tu coche siempre en contacto con el asfalto. 
        Los neumáticos son una pieza clave de seguridad. Toda la información la tienes aquí.`,
        img: `https://${process.env.RODI_AWS_BUCKET}/landings/coche/neumaticos-coche.jpg`,
        route: 'neumaticos.todo_sobre',
      },
      {
        title: 'Cambio de aceite',
        description: `¿Necesitas pasar la revisión? En Rodi tenemos un plan específico para cada coche. 
        Puedes encontrar tu plan de mantenimiento ideal haciendo clic aquí.`,
        img: `https://${process.env.RODI_AWS_BUCKET}/landings/coche/turismo-cambio-aceite-mini_v2.png`,
        route: 'coche.cambio_aceite',
      },
    ],
  },
  {
    title: 'Servicios de Puesta a Punto',
    items: [
      {
        title: 'Escobillas Limpiaparabrisas',
        description: `Su misión es evacuar el agua de la lluvia de las lunas delanteras y traseras del coche. 
        Descubre la importancia que tienen las escobillas para mejorar tu visibilidad.`,
        img: `https://${process.env.RODI_AWS_BUCKET}/landings/coche/escobillas-parabrisas-coche.jpg`,
        route: 'coche.escobillas',
      },
      {
        title: 'Líquido Refrigerante',
        description: `Es el encargado de proteger tu motor de las temperaturas extremas y mantener un funcionamiento óptimo. 
        Entiende cómo funciona este líquido en su sección dedicada.`,
        img: `https://${process.env.RODI_AWS_BUCKET}/landings/coche/liquido-refrigerante-coche.jpg`,
        route: 'coche.liquido',
      },
      {
        title: 'Aire Acondicionado',
        description: `Es una pieza clave del sistema de climatización e indispensable para tu comodidad dentro del coche. 
        En esta sección encontrarás todo sobre el Aire Acondicionado. `,
        img: `https://${process.env.RODI_AWS_BUCKET}/landings/coche/carga-aire-acondicionado-coche.jpg`,
        route: 'coche.carga',
      },
    ],
  },
]

const sectionCa = [
  {
    title: 'Serveis de Mecànica',
    items: [
      {
        title: 'Frens de Cotxe',
        description: `Entendre com funcionen els frens t'ajudarà a saber la millor manera de treure'n el màxim profit. 
        T'ho expliquem aquí.`,
        img: `https://${process.env.RODI_AWS_BUCKET}/landings/coche/disco-de-freno-pinza-de-freno.jpg`,
        route: 'coche.frenos',
      },
      {
        title: 'Amortidors de Cotxe',
        description: `Sabies que els amortidors són un element clau per a què et sentis segur conduint? T'ho expliquem aquí.`,
        img: `https://${process.env.RODI_AWS_BUCKET}/landings/coche/amortiguadores-coche.jpg`,
        route: 'coche.amortiguadores',
      },
      {
        title: 'Corretja de Distribució',
        description: `És l'encarregada de transmetre la força des del motor a les rodes del teu cotxe. 
        Sense la corretja de distribució el moviment seria impossible. T'expliquem aquí tots els detalls.`,
        img: `https://${process.env.RODI_AWS_BUCKET}/landings/coche/correa-de-distribucion-coche.jpg`,
        route: 'coche.correa',
      },
      {
        title: 'Mecànica i Diagnosi',
        description: `No importa el problema, l'important és trobar-li solució. A Rodi estem preparats per 
        tractar qualsevol tipus d'avaria al teu cotxe. T'expliquem com ho fem.`,
        img: `https://${process.env.RODI_AWS_BUCKET}/landings/coche/mecanica-diagnosis-averias-coche.jpg`,
        route: 'coche.mecanica',
      },
    ],
  },
  {
    title: 'Serveis de Manteniment',
    items: [
      {
        title: 'Filtres de Cotxe',
        description: `Un element essencial per al teu cotxe que de vegades passa desapercebut. 
        Coneix els diferents tipus de filtre que existeixen i per a què serveix cadascun.`,
        img: `https://${process.env.RODI_AWS_BUCKET}/landings/coche/filtro-aceite-coche-primer-plano.jpg`,
        route: 'coche.filtros',
      },
      {
        title: 'Bateria de Cotxe',
        description: `És la responsable d'aportar l'energia necessària per a l'arrencada del motor. 
        T'expliquem què és, com funciona i els diferents tipus de bateria que existeixen.`,
        img:
          'https://${process.env.RODI_AWS_BUCKET}/landings/coche/bateria-rodi-coche.jpg',
        route: 'coche.baterias',
      },
      {
        title: 'Pneumàtics de Cotxe',
        description: `Són els responsables de mantenir el teu cotxe sempre en contacte amb l'asfalt. 
        Els pneumàtics són una peça clau de seguretat. Tota la informació la tens aquí.`,
        img: `https://${process.env.RODI_AWS_BUCKET}/landings/coche/neumaticos-coche.jpg`,
        route: 'neumaticos.todo_sobre',
      },
      {
        title: "Canvi d'oli",
        description: `Necessites passar la revisió? A Rodi tenim un pla específic per a cada vehicle. 
        Pots trobar el teu pla de manteniment ideal fent clic aquí.`,
        img: `https://${process.env.RODI_AWS_BUCKET}/landings/coche/turismo-cambio-aceite-mini_v2.png`,
        route: 'coche.cambio_aceite',
      },
    ],
  },
  {
    title: 'Serveis de Posada a Punt',
    items: [
      {
        title: 'Escombretes Eixugaparabrises',
        description: `La seva missió és evacuar l'aigua de pluja dels vidres davanters i de 
        darrera del cotxe. Descobreix la importància que tenen les escombretes per millorar 
        la teva visibilitat.`,
        img: `https://${process.env.RODI_AWS_BUCKET}/landings/coche/escobillas-parabrisas-coche.jpg`,
        route: 'coche.escobillas',
      },
      {
        title: 'Líquid Refrigerant',
        description: `-És l'encarregat de protegir el motor de les temperatures extremes i 
        mantenir un funcionament òptim. Entén com funciona aquest líquid en la seva secció dedicada.`,
        img: `https://${process.env.RODI_AWS_BUCKET}/landings/coche/liquido-refrigerante-coche.jpg`,
        route: 'coche.liquido',
      },
      {
        title: 'Aire Condicionat',
        description: `És una peça clau del sistema de climatització i indispensable per a la teva 
        comoditat dins del cotxe. A aquesta secció trobaràs tot sobre l'aire condicionat.`,
        img: `https://${process.env.RODI_AWS_BUCKET}/landings/coche/carga-aire-acondicionado-coche.jpg`,
        route: 'coche.carga',
      },
    ],
  },
]

const CocheCa = ({ styles }: Props) => (
  <LayoutLanding
    title="Serveis per al teu cotxe"
    textRecomendation="El teu cotxe pateix desgast pel pas del temps i l’ús. 
    Controla els seus elements realitzant la seva revisió periòdica als períodes
     estipulats i gaudiràs sempre d'una conducció còmoda i segura.">
    <React.Fragment>
      <p>
        En <strong>Rodi Motor Services</strong> som experts en el manteniment
        del teu cotxe. Els nostres serveis es centren en oferir solucions de
        mecànica i posada a punt com: manteniment del sistema de frenat,
        corretja de distribució, amortidors, diagnosi d'avaries, bateries, canvi
        d'oli, filtres ... Als tallers Rodi trobaràs una solució de manteniment
        adaptada a les teves necessitats i a les del teu cotxe al millor preu.
      </p>
      <p>
        A continuació, trobaràs tots els serveis que oferim pel teu cotxe. Dins
        de cada secció, t'expliquem què és cada element i la seva importància
        per a què el teu cotxe es mantingui sempre en bon estat.
      </p>
      {sectionCa.map(section => (
        <BoxStaticPageList title={section.title} items={section.items} />
      ))}
    </React.Fragment>
  </LayoutLanding>
)

const CocheEs = ({ styles }: Props) => (
  <LayoutLanding
    title="Servicios para tu coche"
    textRecomendation="Tu coche sufre desgaste por el paso del tiempo y su uso. 
    Controla sus elementos realizando su revisión periódica en los periodos estipulados
     y disfrutarás siempre de una conducción cómoda y segura.">
    <React.Fragment>
      <p>
        En <strong>{getShortCompany()}</strong>
         somos expertos en el
        <strong> mantenimiento de tu coche</strong>. Nuestros servicios se
        centran en ofrecer soluciones de <strong>mecánica</strong> y
        <strong> puesta a punto</strong> como: mantenimiento del sistema de
        frenado, correa de distribución, amortiguación, diagnosis de averías,
        baterías, cambio de aceite, filtros… En los talleres
        {getShortCompany()} encontrarás una solución de mantenimiento adaptada a
        tus necesidades y a las de tu coche al mejor precio.
      </p>
      <p>
        A continuación, encontrarás todos los servicios que ofrecemos para tu
        coche. Dentro de cada sección, te explicamos qué es cada elemento y su
        importancia para que tu coche se mantenga siempre en buen estado.
      </p>
      {sectionsEs.map(section => (
        <BoxStaticPageList
          key={section.title}
          title={section.title}
          items={section.items}
        />
      ))}
    </React.Fragment>
  </LayoutLanding>
)

const CocheContents = ({ ...props }: Props) =>
  locale === 'es' ? <CocheEs {...props} /> : <CocheCa {...props} />

export default CocheContents
